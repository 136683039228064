<template>
  <div class="join">
    <template v-if="!payload">
      <svg height="96" viewBox="0 0 24 24" width="96" fill="currentColor">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
      </svg>
      <h1>Uh oh! Something went wrong here..</h1>
    </template>
    <template v-else-if="!joining">
      <h1>You've been invited to a Quartet session</h1>
      <a
        :href="`quart://${this.payload}`"
        target="blank"
        @click="joining = true"
        >Join Session</a
      >
      <a href="https://quartet.dimsumsoft.com/" target="blank">Install App</a>
    </template>
    <template v-else>
      <quartet-loader />
      <h1>Joining Quartet session..</h1>
      <a :href="`quart://${this.payload}`">Join Session</a>
      <a href="https://quartet.dimsumsoft.com/" target="blank">Install App</a>
    </template>
  </div>
</template>

<script>
import QuartetLoader from "@/components/QuartetLoader.vue";

export default {
  components: {
    QuartetLoader,
  },
  computed: {
    payload() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("p");
    },
  },
  mounted() {
    setTimeout(this.tryOpenApp, 1000);
  },
  data() {
    return {
      joining: false,
    };
  },
};
</script>

<style scoped>
.join {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a {
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 0.1em solid #74828F;
  background-color: #96C0CE;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  box-sizing:  border-box;
  text-decoration: none;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  transition:  all 0.2s;
}

a:hover{
  background-color: #74828F;
}
</style>
